import React, { ReactNode, ElementType } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import MTypography, { TypographyProps } from '@material-ui/core/Typography'

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    whiteSpace: 'pre-wrap', // allow word-wrapping to be set in locize
  },
  dark: {
    color: palette.common.white,
  },
  noWrap: {
    whiteSpace: 'nowrap', // whiteSpace override when noWrap prop is set
  },
  ol: {
    paddingLeft: '1.6rem',
  },
}))

export interface Props extends TypographyProps<ElementType, { component?: ElementType<any> }> {
  children: ReactNode
  className?: string
  kind?: 'light' | 'dark'
  weight?: number
  noWrap?: boolean
}

const Typography = ({ children, kind, className, component, weight, noWrap, ...props }: Props) => {
  const classes = useStyles({})

  return (
    <MTypography
      className={cx(classes.root, classes[kind], className, {
        [classes.ol]: component === 'ol',
        [classes.noWrap]: noWrap,
      })}
      style={weight ? { fontWeight: weight } : undefined}
      component={component}
      {...props}
    >
      {children}
    </MTypography>
  )
}

export default Typography
